var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "telephone" },
    [
      _c("div", { ref: "contentTop" }, [
        _c("div", { staticClass: "tool" }, [
          _c("div", { staticClass: "customer-tool" }, [
            _c(
              "span",
              { staticClass: "tab-bar clearfix" },
              _vm._l(_vm.tabList, function(item, index) {
                return _c(
                  "span",
                  {
                    key: index,
                    class: { active: item.path === _vm.activeName },
                    on: {
                      click: function($event) {
                        return _vm.handleClick(item.path)
                      }
                    }
                  },
                  [_vm._v(_vm._s(item.name))]
                )
              }),
              0
            ),
            _c(
              "div",
              {
                staticStyle: { "line-height": "40px", display: "inline-block" }
              },
              [
                _vm.permUtil.WbCusotmer.callrecordBtn()
                  ? _c("Export", {
                      attrs: {
                        pageParam: _vm.pageParam,
                        searchParam: _vm.searchParam,
                        total: _vm.total,
                        tableData: _vm.tableData,
                        code: "telephone"
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ]),
        _c(
          "div",
          { staticStyle: { width: "94%", margin: "0 auto" } },
          [
            _vm.searchParamList.length > 0
              ? _c("Search", {
                  attrs: {
                    searchAllParam: _vm.searchAllParam,
                    searchParamList: _vm.searchParamList,
                    searchParamFromApi: _vm.searchParamFromApi,
                    code: "telephone"
                  },
                  on: {
                    search: _vm.search,
                    reset: _vm.reset,
                    searchMore: _vm.searchMore,
                    searchList: _vm.searchList
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _c("div", { staticClass: "line" })
      ]),
      _c(
        "el-row",
        { staticStyle: { width: "94%", margin: "0 auto" } },
        [
          _c("el-col", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.audioDate.isPlay,
                    expression: "audioDate.isPlay"
                  }
                ]
              },
              [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.audioDate.currentTime) +
                      " / " +
                      _vm._s(_vm.audioDate.duration)
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "progress-bar--box",
                    on: {
                      click: function($event) {
                        return _vm.updateTime($event)
                      }
                    }
                  },
                  [
                    _c("div", {
                      staticClass: "progress-bar",
                      style: { width: _vm.audioDate.width + "%" }
                    })
                  ]
                )
              ]
            )
          ]),
          _c(
            "el-col",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    }
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "highlight-current-row": "",
                    data: _vm.tableData,
                    "tooltip-effect": "dark",
                    border: "",
                    stripe: "",
                    height: _vm.tableHeight,
                    size: "mini",
                    "element-loading-text": "拼命加载中",
                    "row-style": { height: "0px" },
                    "cell-style": { padding: "0px" },
                    "header-cell-style": { background: "#e5f2ff" }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "学员姓名",
                      prop: "customerName",
                      "min-width": "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.openDetail(scope.row)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(scope.row.customerName) + " "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "呼叫号码",
                      prop: "customerNumber",
                      "min-width": "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "客户编号",
                      prop: "customerId",
                      "min-width": "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      prop: "callTypeName",
                      label: "呼叫类型",
                      "min-width": "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "呼叫时间",
                      prop: "startTime",
                      "min-width": "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "外呼部门",
                      prop: "deptName",
                      "min-width": "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "座席名称",
                      prop: "cnoName",
                      "min-width": "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "座席电话",
                      prop: "clientNumber",
                      "min-width": "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "接听时间",
                      prop: "bridgeTime",
                      "min-width": "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "接听状态",
                      prop: "status",
                      "min-width": "140"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "通话时长",
                      prop: "bridgeDuration",
                      "min-width": "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "总时长",
                      prop: "totalDuration",
                      "min-width": "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "录音",
                      "min-width": "140",
                      fixed: "right"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: scope.row.recordFile,
                                    expression: "scope.row.recordFile"
                                  }
                                ]
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: scope.$index !== _vm.playAudio,
                                        expression: "scope.$index!== playAudio"
                                      }
                                    ],
                                    staticStyle: {
                                      color: "#16b861 !important"
                                    },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.playoTape(
                                          scope.row.id,
                                          scope.$index,
                                          scope.row.trackType
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("播放")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: scope.$index == _vm.pauseAudio,
                                        expression: "scope.$index == pauseAudio"
                                      }
                                    ],
                                    staticStyle: { color: "red!important" },
                                    attrs: {
                                      loading: _vm.audioDate.loading,
                                      icon: "pause",
                                      size: "small",
                                      type: "text"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.stopTape()
                                      }
                                    }
                                  },
                                  [_vm._v("暂停")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.download(
                                          scope.row.id,
                                          scope.row.trackType
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("下载")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "page_test_bottom" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                      "current-page": _vm.pageParam.pageNum,
                      "page-sizes": [10, 20, 50, 100],
                      "page-size": _vm.pageParam.pageSize,
                      total: _vm.total
                    },
                    on: {
                      "size-change": _vm.sizeChange,
                      "current-change": _vm.currentChange
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("Detail", { ref: "drawer", attrs: { method: _vm.getCallRecordList } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }