<!--
 * @Description: 话务
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-04-30 10:06:21
 * @LastEditors: Seven
 * @LastEditTime: 2021-12-02 17:41:49
-->
<template>
  <div class="telephone">
    <div ref="contentTop">
      <div class="tool">
        <div class="customer-tool">
          <span class="tab-bar clearfix">
            <span
              @click="handleClick(item.path)"
              v-for="(item, index) in tabList"
              :class="{ active: item.path === activeName }"
              :key="index"
              >{{ item.name }}</span
            >
          </span>
          <div style="line-height: 40px;display: inline-block;">
           <!-- 导出 -->
            <Export 
              v-if="permUtil.WbCusotmer.callrecordBtn()" 
              :pageParam="pageParam" 
              :searchParam="searchParam" 
              :total="total" 
              :tableData="tableData"
              code="telephone" />
          </div>
        </div>
      </div>
      <div style="width: 94%; margin: 0 auto">
        <Search
          v-if="searchParamList.length > 0"
          :searchAllParam="searchAllParam"
          @search="search"
          @reset="reset"
          @searchMore="searchMore"
          @searchList="searchList"
          :searchParamList="searchParamList"
          :searchParamFromApi="searchParamFromApi"
          code="telephone"
        />
      </div>
      <div class="line"></div>
    </div>
    <el-row style="width: 94%; margin: 0 auto">
      <el-col>
        <div v-show="audioDate.isPlay">
          <span> {{ audioDate.currentTime }} / {{ audioDate.duration }}</span>
          <div class="progress-bar--box" @click="updateTime($event)">
            <div
              class="progress-bar"
              :style="{ width: audioDate.width + '%' }"
            ></div>
          </div>
        </div>
      </el-col>
      <el-col>
        <el-table
          highlight-current-row
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          :height="tableHeight"
          size="mini"
          v-loading="listLoading"
          element-loading-text="拼命加载中"
          :row-style="{ height: '0px' }"
          :cell-style="{ padding: '0px' }"
          :header-cell-style="{ background: '#e5f2ff' }"
        >
          <el-table-column
            type="selection"
            width="55"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="学员姓名"
            prop="customerName"
            min-width="120"
            
          >
            <template slot-scope="scope">
              <el-button type="text" @click="openDetail(scope.row)">
                {{ scope.row.customerName }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="呼叫号码"
            prop="customerNumber"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="客户编号"
            prop="customerId"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"  prop="callTypeName" label="呼叫类型" min-width="120"  ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="呼叫时间"
            prop="startTime"
            min-width="150"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="外呼部门"
            prop="deptName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="座席名称"
            prop="cnoName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="座席电话"
            prop="clientNumber"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="接听时间"
            prop="bridgeTime"
            min-width="150"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="接听状态"
            prop="status"
            min-width="140"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="通话时长"
            prop="bridgeDuration"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="总时长"
            prop="totalDuration"
            min-width="120"
            
          ></el-table-column>
          <el-table-column
            label="录音"
            min-width="140"
            
            fixed="right"
          >
            <template slot-scope="scope">
              <div v-show="scope.row.recordFile">
                <el-button
                  v-show="scope.$index!== playAudio"
                  style="color: #16b861 !important"
                  type="text"
                  @click="
                    playoTape(scope.row.id, scope.$index, scope.row.trackType)
                  "
                  >播放</el-button
                >
                <el-button  
                  :loading="audioDate.loading" 
                  icon="pause" 
                  v-show="scope.$index == pauseAudio"  
                  size="small"  
                  style="color: red!important"
                  type="text" 
                  @click="stopTape()">暂停</el-button>
                <el-button
                  type="text"
                  @click="download(scope.row.id, scope.row.trackType)"
                  >下载</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="page_test_bottom">
          <el-pagination
            background
            @size-change="sizeChange"
            @current-change="currentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="pageParam.pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageParam.pageSize"
            :total="total"
          />
        </div>
      </el-col>
    </el-row>
    <!-- 详情 -->
    <Detail ref="drawer" :method="getCallRecordList" />
  </div>
</template>
<script>
import Search from "components/search";
import Detail from "./components/detail";
import { getUserCustomQuery } from "api/public";
import { getCallRecordList, getCallRecordFile } from "api/customer";
import { formatTimesHour } from "@/utils/timeDate";
import Export from "./components/export";


export default {
  name: "telephone",
  components: {
    Search,
    Detail,
    Export
  },
  data() {
    return {
      activeName: "all",
      tabList: [
        { name: "所有", path: "all" },
        { name: "双方接听", path: "9" },
      ],
      searchAllParam: [
        { name: "name", label: "学员姓名", type: "input", value: "" },
        { name: "customerNumber", label: "呼叫号码", type: "input", value: "" },
        {
          name: "callTime",
          label: "呼叫日期",
          type: "date",
          value: [formatTimesHour(new Date()), formatTimesHour(new Date())],
          ref: "call",
          show: false,
        },
        {
          name: "callType",
          label: "呼叫类型",
          type: "select",
          value: "",
          option: [
            { name: "外呼", id: 0 },
            { name: "回呼", id: 1 },
          ],
          text: "name",
          id: "id",
        },
        {
          name: "statusList",
          label: "接听状态",
          type: "dictMult",
          code: "RECORD_SOURCE",
          value: "",
        },
        { name: "cnoName", label: "坐席名称", type: "input", value: "" },
        { name: "customerId", label: "客户编号", type: "input", value: "" },
      ],
      searchParamList: [],
      defaultParamList: ["name", "customerNumber", "callTime"],
      searchParamFromApi: [],
      tableData: [],
      listLoading: false,
      total: 0,
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        sortItems: [
          {
            asc: false,
            column: "id",
          },
        ],
      },
      playAudio: -1,
      pauseAudio: -1,
      audioDate: {
        duration: "00:00",
        currentTime: "00:00",
        width: "0",
        allTime: "",
        nowTime: "",
        audioObjet: null,
        loading: false,
        isPlay: false,
        id: "",
      },
      searchParam: {},
      // 页面是否有滚动条
      isScroll: true,
      topHeight: 0
    };
  },
  computed: {
    tableHeight() {
      return window.outerHeight - this.topHeight - 256 + 'px'
    }
  },
  mounted() {
    this.activeName = this.$route.query.type || "all"
    this.getSearchList();
    this.handleClick(this.activeName)
    this.createAudio();
  },
  methods: {
    // tab切换
    handleClick(index) {
      this.activeName = index;
      this.$router.push({ query: { type: this.activeName } });
      if(this.activeName == 9) {
        this.searchstatus();
      } else {
        this.search()
      }
    },
     // 查询
     searchstatus() {
      this.searchParam = {};
      this.searchAllParam.map((item) => {
        if (item.value  || ( Array.isArray(item.value)  && item.value.length)) {
          if (item.type === "date") {
            if (item.name === "callTime") {
              this.searchParam.beginTime = item.value[0];
              this.searchParam.endTime = item.value[1];
            }
          } else {
            this.searchParam[item.name] = item.value;
          }
        }
      });
      this.searchParam.statusList = []
      this.searchParam.statusList.push("9")
      this.getCallRecordList();
    },
    // 更新自定义查询
    searchList() {
      this.getSearchList();
    },
    // 更多查询条件
    searchMore(value) {
      this.searchParamList = value
        ? this.searchAllParam
        : this.defaultParamList;
      this.$nextTick(() => {
        this.topHeight = this.$refs.contentTop.offsetHeight + 8
      })
    },
    // 获取自定义查询的默认查询字段
    async getSearchList() {
      const { code, result } = await getUserCustomQuery("telephone");
      if (code === 0) {
        this.defaultParamList = ["name", "customerNumber", "callTime"]
        if (result && result.fieldData && JSON.parse(result.fieldData).length > 0) {
          this.searchParamFromApi = JSON.parse(result.fieldData);
          this.defaultParamList = []
          this.searchParamFromApi.map(() => {
            this.defaultParamList.push("")
          })
          let index
          this.searchAllParam.filter((item) => {
            index = this.searchParamFromApi.findIndex(i => i === item.name)
            this.defaultParamList[index] = item
          });
        } else {
          this.defaultParamList = this.searchAllParam.filter((item) =>
            this.defaultParamList.includes(item.name)
          );
        }
        this.searchParamList = this.defaultParamList;
        this.$nextTick(() => {
          this.topHeight = this.$refs.contentTop.offsetHeight + 8
        })
      }
    },
    // 打开详情
    openDetail(row) {
      this.$refs.drawer.init(row)
    },
    // 获取列表数据
    async getCallRecordList() {
      this.listLoading = true;
      // if(this.activeName == "9") {
      //   this.pageParam.status = this.activeName
      // } else {
      //   this.pageParam.status = ""
      // }
      const { code, result, msg } = await getCallRecordList({ ...this.pageParam, ...this.searchParam });
      if (code === 0) {
        this.tableData = result.list;
        this.total = result.total;
        this.$nextTick(() => {
          this.isScroll = document.body.scrollHeight > window.innerHeight
        })
      } else {
        this.tableData = []
        this.$message.error(msg)
      }
      this.listLoading = false;
    },

    // 改变分页条数
    sizeChange(val) {
      this.pageParam.pageNum = 1
      this.pageParam.pageSize = val;
      this.getCallRecordList()
    },

    // 改变分页
    currentChange(val) {
      this.pageParam.pageNum = val;
      this.getCallRecordList()
    },
    // 播放
    play(url) {
      var audio = document.querySelector("#audio");
      if (audio.paused) {
        audio.src = url;
        this.audioDate.isPlay = true;
        audio.play();
      } else {
        audio.pause();
        audio.currentTime = 0;
      }
    },
    // 暂停播放
    stopTape() {
      if (document.querySelector("#audio")) {
        document.querySelector("#audio").pause();
        this.playAudio = -1;
        this.pauseAudio = -1;
        this.audioDate.isPlay = false;
      }
    },
    // 播放录音
    playoTape(id, index) {
      if (document.querySelector("#audio")) {
        document.querySelector("#audio").pause();
      }
      this.audioDate.loading = true;
      this.audioDate.id = id;
      let type = "0";
      getCallRecordFile(id, type).then((data) => {
        let { code, msg, result } = data;
        if (code === 0) {
          this.recordUrl = result;
          this.play(result);
          this.initAudio(index);
          this.callbackAudio(index);
          this.audioDate.loading = false;
          this.playAudio = index;
          this.pauseAudio = index;
        } else {
          this.$message.error(msg);
          this.audioDate.loading = false;
          this.pauseAudio = -1;
          this.playAudio = -1;
          this.audioDate.isPlay = false;
        }
      });
    },
    // 录音播放完毕
    callbackAudio() {
      var audio = document.querySelector("#audio");
      if (audio) {
        audio.addEventListener("ended", () => {
          this.pauseAudio = -1;
          this.playAudio = -1;
          this.audioDate.currentTime = 0;
          this.audioDate.width = 0;
          this.audioDate.isPlay = false;
        });
      }
    },
    // 下载
    download(id) {
      let type = "1";
      getCallRecordFile(id, type).then((data) => {
        let { code, msg, result } = data;
        if (code === 0) {
          window.open(result);
        } else {
          this.$Message.error(msg);
        }
      });
    },
    // 查询
    search() {
      this.searchParam = {};
      this.searchAllParam.map((item) => {
        if (item.value  || ( Array.isArray(item.value)  && item.value.length)) {
          if (item.type === "date") {
            if (item.name === "callTime") {
              this.searchParam.beginTime = item.value[0];
              this.searchParam.endTime = item.value[1];
            }
          } else {
            this.searchParam[item.name] = item.value;
          }
        }
      });
      if (this.activeName == 9) {
        this.searchParam.statusList = this.searchParam.statusList ? this.searchParam.statusList : []
        this.searchParam.statusList.push("9")
      }

      this.getCallRecordList();
    },
    // 重置
    reset() {
      this.searchParamList.map((item) => {
        if (item.value || item.value == 0) {
          if(item.name === "callTime") {
            item.value = []
          } else if(item.name === "startTime") {
            item.value = [formatTimesHour(new Date()), formatTimesHour(new Date())]
          } else {
            item.value = ""; 
          }
        }
      });
      this.pageParam = {
        pageNum: 1,
        pageSize: 10,
        sortItems: [
          {
            asc: false,
            column: "",
          },
        ],
      };
      this.search();
    },
    initAudio() {
      let _this = this;
      this.audioDate.audioObjet = document.querySelector("#audio");
      this.audioDate.audioObjet.addEventListener("loadedmetadata", () => {
        _this.audioDate.allTime = _this.audioDate.audioObjet.duration;
        _this.audioDate.duration = _this.timeFormat(
          _this.audioDate.audioObjet.duration
        );
      });
      this.audioDate.audioObjet.addEventListener("timeupdate", () => {
        _this.audioDate.nowTime = _this.audioDate.audioObjet.currentTime;
        _this.audioDate.currentTime = _this.timeFormat(
          _this.audioDate.audioObjet.currentTime
        );
        _this.audioDate.width =
          (_this.audioDate.nowTime / _this.audioDate.allTime) * 100;
      });
    },
    updateTime(e) {
      var maxWidth = e.currentTarget.clientWidth;
      this.audioDate.width = (e.offsetX / maxWidth) * 100 + "%";
      this.audioDate.nowTime = this.timeFormat(
        this.audioDate.audioObjet.duration * (e.offsetX / maxWidth)
      );
      this.audioDate.audioObjet.currentTime =
        this.audioDate.audioObjet.duration * (e.offsetX / maxWidth);
    },
    timeFormat(value) {
      var time;
      if (value > -1) {
        var h =
          Math.floor(value / 3600) > 10
            ? Math.floor(value / 3600)
            : Math.floor(value / 3600) == 0
            ? ""
            : "0" + Math.floor(value / 3600);
        var m =
          Math.floor(value / 60) % 60 > 10
            ? Math.floor(value / 60) % 60
            : "0" + (Math.floor(value / 60) % 60);
        var s =
          value % 60 > 10
            ? Math.floor(value % 60)
            : "0" + Math.floor(value % 60);
        time = (h == 0 ? "" : h + ":") + m + ":" + s;
      }
      return time;
    },
    // 生产audio标签
    createAudio() {
      var audio = document.createElement("audio");
      audio.src = "";
      audio.id = "audio";
      document.body.appendChild(audio);
    },
  },
  filters: {
    callType: function (v) {
      if (!v && v !== 0) {
        return "N / A";
      }
      return v == "0" ? "外呼" : "回呼";
    },
  },
};
</script>
<style lang="scss" scoped>
.telephone {
  // padding-bottom: 40px;
  .tool {
    background: #f5f7f9;
  }

  .customer-tool {
    width: 94%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  .tab-bar {
    overflow: hidden;

    .active {
      color: #409eff;
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 4px;
        background-color: #337aff;
        bottom: 0;
        left: 0;
      }
    }

    > span {
      float: left;
      margin-right: 12px;
      line-height: 40px;
      text-align: center;
      text-decoration: none;
      color: #000;
      cursor: pointer;
      font-size: 14px;
    }
  }
  .search-btn {
    position: absolute;
  }

  .tool-btn {
    position: absolute;
    right: 20px;
    top: 0;
  }

  .progress-bar--box {
    width: 100%;
    height: 15px;
    margin: 10px 0;
    background: #c4b8b8;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
  }
  .progress-bar {
    width: 30%;
    height: 15px;
    background: #19be6b;
  }
}
</style>

